import { Component, Vue } from 'vue-property-decorator'
import FormInsurance from '@/components/InsuranceForm/Insurance/Insurance.vue'
import Insurance from '@/Models/Insurance'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import router from '@/router'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import Item from '@/Models/Item'
import Paginator from '@/components/Paginator/Paginator.vue'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormInsurance,
    Search,
    ModalDelete,
    Header,
    Paginator,
    Animation,
  },
})
export default class ViewExpiredPolicies extends Vue {
  showList = true
  data: Array<Insurance> = []
  objInsurance: Insurance = new Insurance()

  url = 'api/insurance/expire_polices/'
  params: Array<Item> = []

  isAction = 'add'
  loading = false
  delete_modal_active = false
  perPage = menuModule.perPage_param
  header: Array<any> = [
    {
      field: 'client_member_number',
      label: '# miembro',
    },
    {
      field: 'name_client',
      label: 'Nombre cliente',
    },
    {
      field: 'policy_number',
      label: '# poliza',
    },
    {
      field: 'period_type_read',
      label: 'Periodicidad',
    },
    {
      field: 'policy_state_read',
      label: 'Poliza estado',
    },
    {
      field: 'date_start_read',
      label: 'Proximo pago',
    },
    {
      field: 'premium_amount',
      label: 'Monto prima',
    },
  ]
  editObj(props: any) {
    this.objInsurance = props
    this.showList = false
    this.isAction = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(`api/insurance/arcr_insurance/${this.objInsurance.id}/`)
      this.data = this.data.filter(
        (a: Insurance) => a.id !== this.objInsurance.id,
      )
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objInsurance = props
  }
  closeModal() {
    this.objInsurance = new Insurance()
    this.delete_modal_active = false
  }
  payment(props: any) {
    this.objInsurance = props
    const id = this.objInsurance.id.toString()
    menuModule.setIdParam(id)
    //console.log('id',window.btoa(id))
    router.push({ name: 'InsurancePayment' })
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objInsurance = new Insurance()
    this.showList = true
    this.isAction = 'add'
  }
  filterItems(items: any) {
    this.data = items.map((obj: any) => ({
      ...obj,
      name_client: obj.member_number.client_name,
      client_member_number: obj.member_number.member_number,
      period_type: obj.period_type.id,
      policy_type: obj.policy_type.id,
      policy_state: obj.policy_state.id,
      policy_state_read: obj.policy_state.policy_state,
      period_type_read: obj.period_type.period_type,
      date_start_read: obj.date_start,
      date_start: Helpers.dateParse(obj.date_start),
      date_next_payment: Helpers.dateParse(obj.date_next_payment),
    }))
  }

  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
